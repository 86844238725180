import * as React from "react";
import * as styles from "./CtaBlocks.module.scss";
import Container from "../../components/container/container";
import parse from "html-react-parser";
import BlockBackground from "../../images/block-background.jpg";
import CustomLink from "../../components/customLink/CustomLink";
import CustomButton from "../../components/customButton/CustomButton";
import BlockPattern from "../../svg/block-pattern.svg";

const ctaBlocksData = [
  {
    title: "Open Developer Roles",
    text: "Lossless is looking for developers with solid smart contract proficiency, strong technical ownership, a firm grasp of financial concepts, and a keen desire to put a stop to Web3 exploits.",
    backgroundUrl: BlockBackground,
    button: (
      <CustomButton text="Coming soon" size="big" type="blur" disabled={true} />
    ),
  },
  {
    title: "Bug Bounty Program",
    text: "Lossless is offering a reward of up to <strong>$50,000</strong> to anyone who reports a high severity bug in the protocol’s core contracts. If you spot a security vulnerability, please submit a report to claim your bounty.",
    button: (
      <CustomLink
        text="Submit a report"
        size="big"
        type="blur"
        url="https://immunefi.com/bounty/lossless/"
        blank={true}
      />
    ),
  },
];

const CtaBlocks = ({}) => {
  return (
    <section className={styles.ctaBlocks}>
      <Container>
        <div className={styles.ctaBlocksWrap}>
          {ctaBlocksData.map((item, index) => (
            <div className={styles.ctaBlocksItemWrap} key={index}>
              <div className={styles.ctaBlocksItem}>
                <div
                  className={styles.ctaBlocksItemBackground}
                  style={{
                    backgroundImage: item.backgroundUrl
                      ? `url(${item.backgroundUrl})`
                      : "none",
                  }}
                >
                  {!item.backgroundUrl && (
                    <div className={styles.ctaBlocksPattern}>
                      <BlockPattern />
                    </div>
                  )}
                </div>

                <h2>{item.title}</h2>
                <p>{parse(item.text)}</p>
                <div className={styles.ctaBlocksItemButton}>{item.button}</div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default CtaBlocks;
