import * as React from "react";
import Layout from "../components/layout/layout";
import Hero from "../sections/hero/hero";
// import Image from "../images/for-developers-hero.jpg";
import CtaBlocks from "../sections/ctaBlocks/CtaBlocks";
import BlocksWithIcons from "../sections/blocksWithIcons/BlocksWithIcons";
import CustomLink from "../components/customLink/CustomLink";
import LottieAnimation from "../components/lootie/Lottie";
import FadeIn from "../components/fade/fade";

import DocumnentationJson from "../json/Documentation.json";
import WhitePaperJson from "../json/Whitepaper.json";
import SdkJson from "../json/SDK.json";
import GithubJson from "../json/Github.json";

import HeroVideo from "../videos/dev.mp4";

const blocksData = [
  {
    items: [
      {
        title: "Documentation",
        text: "Our documentation provides an up-to-date snapshot of Lossless features, use cases, integration details, and the technical tools that simplify the development process.",
        button: (
          <CustomLink
            text="Documentation"
            size="small"
            type="blue"
            url="https://lossless-cash.gitbook.io/lossless/technical-reference/hack-mitigation-protocol"
            blank={true}
          />
        ),
        icon: <LottieAnimation animationJson={DocumnentationJson} />,
      },
      {
        title: "Whitepaper",
        text: "Our whitepaper reviews the types of exploits  Lossless was created to mitigate, detailing the project’s unique architecture, $LSS token utility, and our vision for a more mature Web3 future.",
        button: (
          <CustomLink
            text="Whitepaper"
            size="small"
            type="blue"
            url="https://lossless-cash.gitbook.io/lossless/"
            blank={true}
          />
        ),
        icon: <LottieAnimation animationJson={WhitePaperJson} />,
      },
    ],
  },
  {
    items: [
      {
        title: "SDK",
        text: "Our SDK is designed to help developers interact with the Lossless Protocol, minimising friction and enhancing the developer experience.",
        button: (
          <CustomLink
            text="SDK"
            size="small"
            type="blue"
            url="https://www.npmjs.com/package/@losslesscash/lossless-sdk"
            blank={true}
          />
        ),
        icon: <LottieAnimation animationJson={SdkJson} />,
      },
      {
        title: "Github",
        text: "Our Github serves as the repository for Lossless smart contracts, where everyone is welcome to make contributions to our open-source code.",
        button: (
          <CustomLink
            text="Github"
            size="small"
            type="blue"
            url="https://github.com/Lossless-Cash"
            blank={true}
          />
        ),
        icon: <LottieAnimation animationJson={GithubJson} />,
      },
    ],
  },
];

const ForDevelopersPage = () => {
  return (
    <Layout pageTitle="For developers - Lossless">
      <Hero
        title="Contribute to Web3 Security"
        text="Discover developer tools, components, and integration guides you need to get started with the Lossless Protocol. Learn more about Lossless integration and make a meaningful contribution to the future of Web3 security framework."
        video={HeroVideo}
        titleMaxWidth="71.4rem"
        textMaxWidth="73.4rem"
      />
      <FadeIn>
        <BlocksWithIcons items={blocksData} />
      </FadeIn>
      <FadeIn>
        <CtaBlocks />
      </FadeIn>
    </Layout>
  );
};

export default ForDevelopersPage;
