// extracted by mini-css-extract-plugin
export var big = "CustomButton-module--big--FfR9O";
export var blue = "CustomButton-module--blue--OgXoL";
export var blur = "CustomButton-module--blur--k1qCX";
export var bright = "CustomButton-module--bright--KSn3b";
export var button = "CustomButton-module--button--X9+oQ";
export var isSuccess = "CustomButton-module--isSuccess--uCVmp";
export var loader = "CustomButton-module--loader--3OTVM";
export var loading = "CustomButton-module--loading--enLDJ";
export var small = "CustomButton-module--small--byQL3";
export var spin = "CustomButton-module--spin--nKgzf";
export var success = "CustomButton-module--success--J42pJ";