import * as React from "react";
import * as styles from "./BlocksWithIcons.module.scss";
import Container from "../../components/container/container";

const BlocksWithIcons = ({ items }) => {
  return (
    <section className={styles.blocks}>
      <Container>
        {items.map((item, index) => (
          <div className={styles.blocksWrap} key={index}>
            {item.items.map((block, blockIndex) => (
              <div className={styles.blocksItem} key={blockIndex}>
                <div className={styles.blocksItemIcon}>{block.icon}</div>
                <h2>{block.title}</h2>
                <p>{block.text}</p>
                <div className={styles.blocksItemButton}>{block.button}</div>
              </div>
            ))}
          </div>
        ))}
      </Container>
    </section>
  );
};

export default BlocksWithIcons;
