import * as React from "react";
import * as styles from "./CustomButton.module.scss";
import ArrowSvg from "../../svg/arrow-right.svg";
import ArrowSvgFull from "../../svg/arrow-right-full.svg";
import cn from "classnames";
import LoaderIcon from "../../svg/loader.svg";
import CheckIcon from "../../svg/check.svg";

const CustomButton = ({
  text,
  type,
  size,
  disabled,
  onClick,
  loading,
  success,
}) => {
  return (
    <button
      className={cn(
        styles.button,
        styles[type],
        styles[size],
        loading && styles.loading,
        success && styles.isSuccess
      )}
      disabled={disabled ? true : false}
      type="submit"
      onClick={onClick}
    >
      {text}
      {!disabled && <>{size === "big" ? <ArrowSvgFull /> : <ArrowSvg />}</>}

      {loading && (
        <div className={styles.loader}>
          <LoaderIcon />
        </div>
      )}

      {success && (
        <div className={styles.success}>
          <CheckIcon /> Success
        </div>
      )}
    </button>
  );
};

export default CustomButton;
